/* global appConfig */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import { userActions } from '@actions/admin';

import { DeviceFingerprint, checkBadPassword, convertCountryToCode, isValidMobile, TrackEvent } from '@lib/simpletools';
import { Loader } from '@common/admin';
import PhoneInput from '@common/phoneinput';
import { isValidNumber } from 'libphonenumber-js'
import { RenderPasswordPolicy } from '@common/changepassword';

import logo from '../../assets/images/athena/AthenaBoardLogo_small.png';
import '../loginpage/loginapp.css';
import { commonErrorMessages } from '@constants';
import { RoutesConstants } from '@constants/common.constants';
import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@mui/material';
import { MuiCheckbox } from '../common/MUI/MuiCheckbox';
import { MuiButton } from '../common/MUI';

class SignUpClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      company: '',
      title: '',
      specialty: '',
      profession: '',
      city: '',
      country: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      token: '',
      deviceHash: '',
      hasError: false,
      error: '',
      hasAccepted: false,

      passwordPolicy: null,
      passwordPolicyRegex: '',

      loading: false,
      completed: false,

      searchCity: '',
      citiesList: [],
      loadingSearch: false,

      titleList: [],

      showHint: false,
      hintBottom: 0,
      tcChecked: false,
    }

    this.list = []
    this.listSpecialty = []
    this.listTitles = []
    this.searchTimer = null
    this.myAccountRef = null
    this.refPassCode1 = null

    this.filterList = this.filterList.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  componentDidMount() {
    console.log("SignUpClass",window.location.search, location)
    let token = null, email = null
    if (this.props.location.hasOwnProperty('query')) {
      var board = this.props.location.query;
      console.log("parameters",board)
      token = board.token
      // email = board.email
    }else{
      const urlParams = new URLSearchParams(window.location.search);
      token = urlParams.get('token')
      // email = this.getEmail('email')
    }

    if(token === null) {
      this.setState({ hasError: true })
      return
    }

    if(localStorage.getItem('acceptCookies'))
      this.setState({hasAccepted: true})

    // const alias = email
    this.setState({ token: token })

    DeviceFingerprint('login')
    .then((hash) => {
      this.setState({deviceHash: hash});
    })
    .catch(function(error) {
    });

    this.props.dispatch(userActions.getTrailNewUserInfo(token))
    this.loadNames();
    // this.loadJobs();
  }

  componentDidUpdate(){
    if (this.props.existingUser === true && this.props.username !== undefined && this.props.username !== ""
      && this.props.clientId !== undefined && this.props.clientId !== ""
    ) {
      this.props.history.push(RoutesConstants.signupexistinguser)
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    state.completed = false

    if(nextProps.passwordPolicy !== undefined){
        state.passwordPolicy = nextProps.passwordPolicy
        state.passwordPolicyRegex = nextProps.passwordPolicy.regex
    }

    if(state.firstname.trim() !== "" && state.lastname.trim() !== "" && state.company.trim() !== "" && state.city !== ""
        && state.password !== "" && state.confirmPassword !== "" && state.password === state.confirmPassword
        && nextProps.username !== undefined && nextProps.username !== ""){

      var wordsList = [
        nextProps.username.replace(/[0-9]/g, '').toLowerCase().trim(),
      ];

      if(state.mobile !== "" && isValidNumber(state.mobile) && isValidMobile(state.mobile))
        state.completed = true
      else if(state.mobile === "" || state.mobile.length < 4)
        state.completed = false

      var regex = new RegExp(wordsList.join('|'));
      if(state.password.toLowerCase().match(regex)){
        state.completed = false
      }else if(checkBadPassword(state.password.toLowerCase())){
        state.completed = false
      }else if(state.passwordPolicyRegex !== ""){
        if(state.password.match(state.passwordPolicyRegex) === null){
          state.completed = false
        }
      }else if(state.password !== state.confirmPassword){
        state.completed = false
      }
    }

    if(nextProps.loggedIn){
      state.loading = true
    }else{
      state.loading = false
    }

    return state
  }

  async loadNames() {
    const response = await fetch('/lib/countries.min.json');
    const names = await response.json();

    var l = []
    for(const country in names){
      names[country].forEach((city) => {
        if(l.indexOf(city+", "+country) === -1)
          l.push({
            label: city+", "+country,
            value: city+", "+country,
            country: country,
            city: city,
          })
      });

    }

    this.list = l
  }

  handleValidation(){
    setTimeout(()=>{
      if(this.state.searchCity !== "" && this.state.city === ""){
        this.setState({error: "Please select your city from the list"})
      }else if(this.state.mobile.length >= 4 && (!isValidNumber(this.state.mobile) || !isValidMobile(this.state.mobile))){
        this.setState({error: "Mobile number is invalid"})
      }else if(this.state.password !== "" && this.state.confirmPassword !== "" && this.state.confirmPassword !== this.state.password){
        this.setState({error: "Password and confirm password doesn't match"})
      }else if(this.checkPassword()){
        this.setState({error: ""})
      }
    }, 4000)
  }

  handleChange(name, value){
    if(/^\s/.test(value)) return;//no space in the start of input
    this.setState({ [name]: value })
    this.handleValidation();
  }

  acceptCookies(){
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  filterList(){
    const citiesList = this.list.filter(o => o.city.toLowerCase().startsWith(this.state.searchCity.toLowerCase()))
    this.setState({citiesList, loadingSearch: false});
  }

  getEmail(){
    const c = window.location.search.trim()
    const d = c.split("&email=")
    if(d < 2) return ""
    const l = d[1].split('&')
    return l[0]
  }

  onSetHint(){
    if(this.refPassCode1 !== null && this.state.hintBottom === 0){
      this.getPosition();
    }
    this.setState({showHint: true})
  }

  onExitHint(){
    this.setState({showHint: false})
    this.checkPassword()
  }

  onTcChange = () => {
    this.setState({
      tcChecked: !this.state.tcChecked
    });
  }

  getPosition(){
    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight,
                           html.clientHeight, html.scrollHeight, html.offsetHeight );
    var off = height - this.refPassCode1.offsetTop;
    this.setState({
      hintBottom: off
    });
  }

  checkPassword(){
    if (this.state.password !== "" && this.props.username !== undefined && this.props.username !== "") {
      var wordsList = [
        this.props.username.replace(/[0-9]/g, '').toLowerCase().trim(),
      ];

      var regex = new RegExp(wordsList.join('|'));
      if (this.state.password.toLowerCase().match(regex)) {
        this.setState({ error: commonErrorMessages.password.namePasswordError });
        return false
      } else if (checkBadPassword(this.state.password.toLowerCase())) {
        this.setState({ error: commonErrorMessages.password.commonPasswordError });
        return false
      } else if (this.state.passwordPolicyRegex !== "") {
        if (this.state.password.match(this.state.passwordPolicyRegex) === null) {
          this.setState({ error: commonErrorMessages.password.minimumRequirementError });
          return false
        }
      }
    }
    return true
  }

  requiredFieldIcon(state){
    if((state === 'mobile' && this.state[state].length < 4) || this.state[state].trim() === "")
      return <span style={{color: 'red'}}> *</span>
  }

  render(){
    const { loggingIn, loadingInfo, loadingInfoError } = this.props;
    if(this.state.hasError || loadingInfoError !== undefined)
      return (
        <div className="page spacebetween maxHeight logContent shadeWhite" style={{minHeight: '100vh'}}>
          <div>
            <div className="signs1logo">
              <a href="https://www.athenaboard.com">
                <img style={{width: '150px', marginLeft: '150px', marginBottom: '50px', marginTop: '40px'}}  src={logo} />
              </a>
            </div>
            <div className="signs1start">
              <h4>Problem with creating the profile</h4>
              <p className='size18'>{loadingInfoError !== undefined && loadingInfoError}</p>
            </div>
          </div>
        </div>
      )
    else if (loadingInfo)
      return (
        <div className='centericon'>
          <div className="page">
            <label className="colorAthena">Verifying your information</label>
            <div className='centericon'>
              <Loader />
            </div>
          </div>
        </div>
      )
    return (
      <div className="page spacebetween maxHeight logContent shadeWhite" style={{minHeight: '100vh', width: '100%'}}>
        <div>
          <div className="signs1logo">
            <a href="https://www.athenaboard.com">
              <img style={{width: '150px', marginLeft: '150px', marginBottom: '50px', marginTop: '40px'}} src={logo} />
            </a>
          </div>
          <div className="signs1start">
            <h4>Complete your profile</h4>
            <p>You're signing up with {this.props.username}</p>
            <div className="signUpPanel">
              <div className="signq">
                <label>First name{this.requiredFieldIcon('firstname')}</label>
                <TextField
                  variant='outlined'
                  value={this.state.firstname}
                  onChangeValue={(e)=>{this.handleChange('firstname',e)}}
                  />
              </div>
              <div className="signq">
                <label>Last name{this.requiredFieldIcon('lastname')}</label>
                <TextField
                  variant='outlined'
                  value={this.state.lastname}
                  onChangeValue={(e)=>{this.handleChange('lastname',e)}}
                  />
              </div>
              <div className="signq">
                <label>Company{this.requiredFieldIcon('company')}</label>
                <TextField
                  variant='outlined'
                  value={this.state.company}
                  onChangeValue={(e)=>{this.handleChange('company',e)}}
                  />
              </div>
              <div className="signq">
                <label>City{this.requiredFieldIcon('city')}</label>
                <Autocomplete
                  name="city"
                  selected={this.state.city}
                  onSelected={(e)=>{
                    this.handleChange('city',e.city)
                    this.handleChange('country',e.country)
                    this.setState({ loadingSearch: false, searchCity: e.value })
                  }}
                  onChangeValue={(e)=>{
                    if(e === "") return
                    this.setState({ loadingSearch: true, searchCity: e })
                    clearTimeout(this.searchTimer)
                    this.searchTimer = setTimeout(this.filterList,1000)
                  }}
                  onToggle={open=>{
                    if(!open) this.setState({ loadingSearch: false, searchCity: "" })
                  }}
                  placeholder="Enter and select your location or closest city"
                  items={this.state.citiesList.slice(0, 20)}
                  defaultSelected={null}
                  filter="none"
                  insertMode={false}
                  loading={this.state.loadingSearch}
                  value={this.state.searchCity}
                  multiple={false}
                  size="Medium"
                  status="Good"
                  />
              </div>
              <div className="signq">
                <label>Mobile{this.requiredFieldIcon('mobile')}</label>
                <PhoneInput
                  value={this.state.mobile}
                  onChange={(e)=>{this.handleChange('mobile',e)}}
                />
              </div>
              <div className="signq">
                <label>Password{this.requiredFieldIcon('password')}</label>
                {this.state.passwordPolicy !== null && this.state.showHint &&
                  <div
                    className="login-reg centerAbs"
                    ref={(node) => this.myAccountRef = node}
                    style={{bottom: this.state.hintBottom}}
                    >
                    <div className="page">
                      <RenderPasswordPolicy
                        policy={this.state.passwordPolicy}
                        password={this.state.password}
                        />
                    </div>
                  </div>
                }
                <TextField
                  type='password'
                  stylenormal='freemium-password-input'
                  autoComplete='new-password'
                  value={this.state.password}
                  onFocusIn={this.onSetHint.bind(this)}
                  onFocusOut={this.onExitHint.bind(this)}
                  onChange={(e)=>{this.handleChange('password',e.target.value)}}
                  refpoint={(node) => { this.refPassCode1 = node;}}
                  />
              </div>
              <div className="signq">
                <label>Confirm password{this.requiredFieldIcon('confirmPassword')}</label>
                <TextField
                  type='password'
                  stylenormal='freemium-password-input'
                  autoComplete='new-password'
                  value={this.state.confirmPassword}
                  onChange={(e)=>{this.handleChange('confirmPassword',e.target.value)}}
                  />
              </div>
              {((this.props.error !== undefined && this.props.error !== "") || this.state.error !== "") && (
                  <div className="login-error-msgapp-freemium">
                    <span className="animated fadeInUp">
                      <span>{this.state.error !== "" ? this.state.error : this.props.error}</span>
                    </span>
                  </div>
                )}
            </div>
            <FormControlLabel control={<MuiCheckbox background='white' checked={this.state.tcChecked} onChange={this.onTcChange} />} label={<div>
              <span>I have read and I agree to the&nbsp;
                <a href="https://www.athenaboard.com/terms-conditions" target="_blank">Terms and Conditions</a>,&nbsp;
                <a href="https://www.athenaboard.com/cookie-policy" target="_blank">Cookie Policy</a>&nbsp;
                and <a href="https://www.athenaboard.com/privacy" target="_blank">Privacy Policy</a>.
              </span>
            </div>}
            />
            <div className="signs1but">
              <MuiButton
                variant='contained'
                loading={this.state.loading}
                disabled={!this.state.completed || !this.state.tcChecked}
                onClick={()=>{
                  const countryCode = convertCountryToCode(this.state.country)
                  this.props.dispatch(userActions.completeSignUp({
                    firstname: this.state.firstname.trim(),
                    lastname: this.state.lastname.trim(),
                    companyName: this.state.company.trim(),
                    // title: this.state.title,
                    // specialty: this.state.specialty,
                    // profession: this.state.profession,
                    city: this.state.city,
                    country: countryCode,
                    mobile: this.state.mobile,
                    password: this.state.password,
                    token: this.state.token,
                    username: this.props.username,
                    deviceHash: this.state.deviceHash,
                  }))
                }}
                >Complete profile</MuiButton>
            </div>
          </div>
        </div>
        {loggingIn &&
          <div className="spin-overlay">
            <CircularProgress color='success' size={48} />
          </div>
        }
        {this.state.hasAccepted &&
          <div className="cookieAccept">
            <div className="cookieText">
              <label>Use of this site accepts the <a href="https://www.athenaboard.com/cookie-policy" className="cookieLink">use of cookies</a>.</label>
              <div className="cookieBut">
                <MuiButton variant='contained' onClick={this.acceptCookies.bind(this)}>Ok</MuiButton>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    checkEmail: state.authentication.checkEmail,
    username: state.authentication.username || '',
    existingUser: state.authentication.existingUser,
    clientId: state.authentication.clientId,
    loggedIn: state.authentication.loggedIn,
    loggingIn: state.authentication.loggingIn,
    passwordPolicy: state.authentication.passwordPolicy,
    error: state.authentication.error,
    loadingInfo: state.authentication.loadingInfo,
    loadingInfoError: state.authentication.loadingInfoError,
  };
}

const connectedSignUpPage = connect(mapStateToProps)(SignUpClass);
export { connectedSignUpPage as SignUpNewUser };
